import { Heading, Button, Text, Flex } from '@chakra-ui/react';
import * as React from 'react';
import { Link } from 'gatsby';

import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';

const NotFoundPage: React.FC = () => (
  <Layout>
    <SEO title="Page not found" />
    <Flex minH="70vh" direction="column" alignItems="center">
      <Heading p={5}>Page Not Found</Heading>
      <Text p={5}>
        Looks like you have come across a page that is missing or may have been
        moved.
      </Text>
      <Button as={Link} to="/" size="lg" my={5}>
        Return to the Home page
      </Button>
    </Flex>
  </Layout>
);

export default NotFoundPage;
